import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class DunatiSelectComponent extends Vue {
  @Prop({ default: null })
  public data: any;

  @Prop({ default: null })
  public options: any;

  @Prop({ default: 'FILTRAR POR' })
  public selected: any;

  @Prop({ default: 'normal' })
  public filter: any;
  public isHovering: boolean = true;

  private list: any = [];
  private selectedItem = '';

  public mounted() {
    this.selectedItem = this.selected;
    this.list = this.data;

    if (this.filter === 'fixed' && this.list.length > 0) {
      this.selectItem(this.list[0]);
    }
  }

  public selectItem(item: any) {

    if (this.filter === 'fixed') {
      this.selectedItem = `${this.selected}: ${item[this.options.showField]} + ${ '$' + this.formatMoney(item[this.options.extraField], 0) }`;
    } else {
      this.selectedItem = item[this.options.showField];
    }

    this.$emit('selected', item);
    this.isHovering = false;
  }

  public getIva(price: any) {
    return (+price > 0) ? '+ IVA' : '';
  }

  public formatMoney(number: any, fixed: number = 0, sections: number = 0): number {
    if (!number) { return 0; }

    const re = '\\d(?=(\\d{' + (sections || 3) + '})+' + (fixed > 0 ? '\\.' : '$') + ')';
    return (number).toFixed(Math.max(0, ~~fixed)).replace(new RegExp(re, 'g'), '$&.');
  }
}

