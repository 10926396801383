import { ProductCategory } from './../../class/productCategory';
import { Product } from './../../class/product';
import Vue from 'vue';
import Component from 'vue-class-component';
import CardProductSComponent from '@/components/card-product-s/card-product-s.component';
import { ApiServices } from '@/services/api.services';
import DunatiSelectComponent from '@/components/dunati-select/dunati-select.component';
import DunatiLoaderComponent from '@/components/dunati-loader/dunati-loader.component';
import DunatiLoaderSimpleComponent from '@/components/dunati-loader-simple/dunati-loader-simple.component';
import { DUNATI_DOMAIN, DUNATI_ORGANIZATION_SCHEMA, ROBOT_CONTENT } from '@/main';
import { StrapiProduct } from '@/class/strapiProduct';
import { Subcategory } from '@/class/subcategory';


@Component({
  components: {
    CardProductSComponent,
    DunatiSelectComponent,
    DunatiLoaderComponent,
    DunatiLoaderSimpleComponent
  },
  props: ['category']
})
export default class ProductsListComponent extends Vue {

  public load = false;
  public banner = '';
  public title = '';
  public images = [];
  public description = '';
  public listPlaceholders = Array(8).fill(1);
  public listProducts: StrapiProduct[] = [];
  public listProductsKeep: StrapiProduct[] = [];
  public selected: any = null;
  public currentOrder: any = null;
  public currentCategory: any = null;
  public order: number = 0;

  public options = [
    { id: 1, name: 'Recomendados' },
    { id: 2, name: 'Precio menor a mayor' },
    { id: 3, name: 'Precio mayor a menor' },
  ];
  public itemsPeerPage = 12; // cantidad de productos a mostrar
  public itemsPeerPageKeep = 12; // para resetear al anterior

  // gets
  get category(): string {
    return this.$props.category;
  }

  get subcategory(): string {
    return this.$route.params.subcategory || '';
  }

  get slug(): string {
    return this.$route.path || '';
  }

  get subcategories(): Subcategory[] {
    let subcs = this.$store.getters.subcategories;
    return subcs.filter((x: Subcategory) => x.category === this.category);
  }

  get subcategoryText(): any {
    const current = this.subcategories.find(x => x.slug === this.subcategory);
    return current? current.text : '';
  }

  constructor() {
    super();

    if (!this.subcategories.length) {
      this.$store.dispatch('loadSubcategories');
    }
  }

  public getInitData(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.category === 'sillas') {
        this.banner = '/assets/images/chair_category/Sillas-de-oficinas.jpg';
        this.title = 'SILLAS DE OFICINA';
        this.description = 'Sillas de escritorio. Ejecutivas, operativas, de visita y para reuniones';
      } else if (this.category === 'mesas') {
        this.banner = '/assets/images/table_category/Mesas-de-reunion.jpg';
        this.title = 'MESAS';
        this.description = ' Mesas de reuniones con características minimalistas, simples, pero a la vez elegante. La series de conferencia promete en todas sus disposiciones un acabo ligero, estético y sobretodo libre.';
      } else if (this.category === 'almacenamiento') {
        this.banner = '/assets/images/storage_category/Cajoneras-armarios-y-bibliotecas-para-oficina.jpg';
        this.title = 'ALMACENAMIENTO';
        this.description = 'Una completa línea de cajoneras y librerías. Nuestro resultado final: propuestas modernas con capacidades brillantes para el manejo archivos.';
      } else if (this.category === 'sofas y sillas multiuso') {
        this.banner = '/assets/images/chair_category/Sofas-y-sillas-multiuso.jpg';
        this.title = 'SOFÁS Y SILLAS MULTIUSO';
        this.description = 'Sofás y sillas multiuso.';
      }

      let productWhere = `where[category]=${this.category}`;
      if (this.subcategory) productWhere += `&where[subcategory]=${this.subcategory}`;
      
      ApiServices.getStrapiProducts(productWhere).then(async (pResponse) => {
        const products: StrapiProduct[] = pResponse.data.data || [];
        let relatedSlugs: string[] = []        

        products.forEach((element) => {
          let aux = new StrapiProduct(element);
          this.listProducts.push(aux);

          if (aux.relatedSlug) {
            relatedSlugs.push(aux.relatedSlug);
          }

          if (aux.category && aux.category.name === this.category && !this.subcategory) {        
            this.description = aux.category.metaDescription !== ''? aux.category.metaDescription : this.description;
          }

          if (aux.subcategory && aux.subcategory.slug === this.subcategory) {
            this.description = aux.subcategory.metaDescription !== ''? aux.subcategory.metaDescription : this.description;
          }
        });        

        //await this.getProductsByRelated(relatedSlugs);
        this.listProducts = this.listProducts.sort((a: StrapiProduct, b: StrapiProduct) => (b.evaluation - a.evaluation));
        this.listProductsKeep = this.listProducts;
        this.load = true;

        const len = this.listPlaceholders.length;
        for (let i = 0; i < len; i++) {
          this.listPlaceholders.pop();
        }

        this.updateTags();
        this.updateJsonld();
        setTimeout(() => {
          return resolve();
        }, 1);
      })
    });
  }

  public mounted() {
    this.$store.dispatch('changeMenu', 'normal');
    window.addEventListener('scroll', this.handleScroll);
    this.getInitData();
  }

  public destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  public handleScroll(event: Event) {
    if (typeof window !== 'undefined' && this.load) {
      const div = document.querySelector('.cnt-list-products');
      const st = window.scrollY || document.documentElement.scrollTop;

      if(div && st >= (div.scrollHeight - (div.scrollHeight * 0.20))) {
        this.loadMore();
      }
    }
  }

  public serverPrefetch() {
    return this.getInitData();
  }

  public getUpper(text: string): string {
    return text ? text.toLocaleUpperCase() : '';
  }

  public goBack(): void {
    this.$router.push({ name: 'products' });
  }

  public showCategory(category: string): void {
    let subcategory = category;

    let obj: any = {};
    if (subcategory ) obj.subcategory = subcategory;

    this.$router.push({ name: this.$route.name, params: obj });
  }

  public loadMore(): void {
    if(this.itemsPeerPage > this.listProducts.length) return;

    let oldPosition: number = window.scrollY;
    this.itemsPeerPage += this.itemsPeerPageKeep;

    this.$nextTick(() => {
      window.scrollTo(0, oldPosition);
    });
  }

  private getProductsByRelated(slugs: string[]): Promise<void> {
    return new Promise(async (resolve, reject) => {
      ApiServices.getProducts(`where[slugs][in]=${slugs}`).then((pResponse) => {
        let dataResponse = pResponse.data.data.data || [];
  
        dataResponse.forEach((element: any) => {
          this.listProducts.forEach((product: any) => {
            if (product.relatedSlug === element.slug) {
              this.$nextTick(() => {
                product.price = +element.unitPrice;
              })
            }
          })
        });

        resolve()
      }, () => resolve())
    })
  }
  

  public updateTags(): void {
    this.$options.dunatiHeadTags = {
      title: `${this.title} - Muebles de oficina | Dunati`,
      metaTags: [
        // Primaty Tags /
        {
          name: 'description',
          content: this.description,
        },
        {
          name: 'robot',
          content: ROBOT_CONTENT,
        },
        // Open Graph / Facebook /
        {
          property: 'og:type',
          content: 'product',
        },
        {
          property: 'og:url',
          content: `${DUNATI_DOMAIN}${this.slug}`,
        },
        {
          property: 'og:title',
          content: `${this.title} - Muebles de oficina | Dunati`,
        },
        {
          property: 'og:description',
          content: this.description,
        },
        {
          property: 'og:image',
          content: `${DUNATI_DOMAIN}${this.banner}`,
        },
        // Twitter /
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:url',
          content: `${DUNATI_DOMAIN}${this.slug}`,
        },
      ],
    };
    // @ts-ignore
    this.renderTags();
  }

  private updateJsonld() {
    let listForJson: object[] = [];
    listForJson.push(DUNATI_ORGANIZATION_SCHEMA);
    listForJson.push({
      "@context": "http://schema.org",
      "@type":"ItemList",
      "mainEntityOfPage":{
        "@type":"CollectionPage",
        "@id": `${DUNATI_DOMAIN}${this.slug}`
      },
      "itemListElement": [
        this.getListItem()
      ]
   });

   for (let i = 0; i < this.listProductsKeep.length; i++) {
    const element = this.listProductsKeep[i];

    listForJson.push({
        "@context": "http://schema.org/",
        "@type": "Product",
        "@id": element.slug,
        "url": `${DUNATI_DOMAIN}${this.slug}/${element.slug}`,
        "name": element.name,
        "brand": "Dunati",
        "description": element.metaDescription,
        "category": `Inicio > PRODUCTOS > ${this.title.toUpperCase()} > ${element.name.toUpperCase()}`,
        "image": [
          element.img.url
        ],
        "offers": {
          "priceCurrency": "CLP",
          "@type": "AggregateOffer",
          "lowPrice": element.price || 0,
          "highPrice": element.price || 0,
          "offerCount": element.colors
        }
      });
    }

    this.$options.dunatiJsonld = listForJson;

    // @ts-ignore
    this.renderJsonld();
  }

  private getListItem(): object[] {
    let listItems: object[] = [];
    for (let i = 0; i < this.listProductsKeep.length; i++) {
      const element = this.listProductsKeep[i];
      
      listItems.push({
        "@type": "ListItem",
				"position": i + 1,
				"item": { 
          "@id": element.slug
        }
      });
    }

    return listItems;
  }
}

