import { render, staticRenderFns } from "./dunati-select.component.html?vue&type=template&id=551c69a7&scoped=true&"
import script from "./dunati-select.component.ts?vue&type=script&lang=ts&"
export * from "./dunati-select.component.ts?vue&type=script&lang=ts&"
import style0 from "./dunati-select.component.scss?vue&type=style&index=0&id=551c69a7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "551c69a7",
  null
  
)

export default component.exports